<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Circulars</h4>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_length"
                                    class="dataTables_length"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Show&nbsp;
                                        <b-form-select
                                            v-model="perPage"
                                            size="sm"
                                            :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_filter"
                                    class="dataTables_filter text-md-right"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Search:
                                        <b-form-input
                                            v-model="filter"
                                            type="search"
                                            placeholder="Search..."
                                            class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive mb-0">
                            <b-table
                                :items="docs"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                            >
                                <template v-slot:cell(detail)="data">
                                    <b-button
                                        variant="primary"
                                        @click="
                                            viewDocument(data.item.document_url)
                                        "
                                        >View</b-button
                                    >
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                    class="dataTables_paginate paging_simple_numbers float-right"
                                >
                                    <ul
                                        class="pagination pagination-rounded mb-0"
                                    >
                                        <b-pagination
                                            v-model="currentPage"
                                            :total-rows="rows"
                                            :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import { BASE_URL } from "../../common";
import axios from "axios";

export default {
    components: {
        Layout,
    },
    data() {
        return {
            docs: [],
            data: "",
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fields: [
                { key: "id", sortable: true },
                { key: "title", sortable: true },
                { key: "created_at", sortable: true },
                "Detail",
            ],
        };
    },
    mounted() {
        if (localStorage.cs_user_token) {
            axios
                .get(
                    `${BASE_URL}circulars/?token=${localStorage.cs_user_token}`
                )
                .then((res) => {
                    this.docs = res.data.response;
                });
        }
    },
    methods: {
        viewDocument(url) {
            console.log(url);
            let fileExtension = url.split(".")[1].toLowerCase();
            console.log(fileExtension);
            if (
                fileExtension == "jpg" ||
                fileExtension == "jpeg" ||
                fileExtension == "png" ||
                fileExtension == "gif"
            ) {
                let routeData = this.$router.resolve({
                    name: "imagePreview",
                    query: {
                        data: url,
                    },
                });
                window.open(routeData.href, "_blank");
            } else if (fileExtension == "pdf") {
                let routeData = this.$router.resolve({
                    name: "pdfPreview",
                    query: {
                        data: url,
                    },
                });
                window.open(routeData.href, "_blank");
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
    },
};
</script>
